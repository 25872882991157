export default {
  okText: 'Confirm',
  closeText: 'Close',
  cancelText: 'Cancel',
  loadingText: 'Loading...',
  saveText: 'Save',
  delText: 'Delete',
  resetText: 'Reset',
  searchText: 'Search',
  queryText: 'Query Now',
  nextText: 'Next Step',

  inputText: 'Please enter',
  chooseText: 'Please choose',

  add: 'Add',
  update: 'Update',
  refresh: 'Refresh',
  back: 'Return',

  light: 'Light',
  dark: 'Dark',
  countdown: {
    normalText: 'Get Verification Code',
    sendText: 'Try again in {0}s',
  },
};
